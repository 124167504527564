const XlocIcon = (props) => {

    return (
        <i style={{display: 'flex', justifyContent: 'center'}}>
            <svg width={props.width} className='xloc-logo' viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" fill='currentColor'>
                <path key='up' d="M7.34 47.05c-.09-.08-.16-.23-.16-.32 0-.09 3.67-3.82 8.15-8.29 6.49-6.49 8.21-8.21 8.4-8.21s1.91 1.72 8.4 8.21c4.48 4.47 8.15 8.2 8.15 8.29 0 .19-.25.48-.42.48-.07.01-3.72-1.79-8.13-4.01l-8.03-4.01-8.02 4.01c-4.42 2.22-8.06 4.02-8.13 4.01-.06 0-.18-.07-.26-.16z"/>
                <path key='right' d="M.92 40.56c-.08-.08-.16-.21-.16-.27 0-.07 1.79-3.72 4.01-8.13l4.01-8.03-4.01-8.02c-2.22-4.42-4.02-8.06-4.01-8.13 0-.17.29-.43.48-.43.09 0 3.82 3.67 8.29 8.15 6.49 6.49 8.21 8.21 8.21 8.4s-1.72 1.91-8.21 8.4c-4.47 4.48-8.2 8.15-8.29 8.15-.09 0-.24-.07-.32-.16z"/>
                <path key='down' d="M37.5 32.5c-6.49-6.49-8.21-8.21-8.21-8.4s1.72-1.91 8.21-8.4c4.47-4.48 8.15-8.15 8.29-8.15.19 0 .48.26.48.43 0 .07-1.79 3.72-4.01 8.13l-4.01 8.02 4.01 8.03c2.22 4.41 4.02 8.06 4.01 8.13 0 .17-.29.43-.48.43-.09 0-3.82-3.67-8.29-8.15z"/>
                <path key='left' d="M15.15 9.68c-4.48-4.47-8.15-8.2-8.15-8.29 0-.19.26-.48.43-.48.07-.01 3.72 1.79 8.13 4.01l8.02 4.01 8.03-4.01c4.41-2.22 8.06-4.02 8.13-4.01.17 0 .43.29.43.48 0 .09-3.67 3.82-8.15 8.29-6.49 6.49-8.21 8.21-8.4 8.21s-1.91-1.72-8.4-8.21z"/>
            </svg>
        </i>
    );

};

export { XlocIcon };